import './App.css';
import React, {useEffect, useState} from 'react';
import {Header, ProgressSpinner} from 'olaqin-design-system';
import 'olaqin-design-system/src/assets/odsicons/odsicons.css';
import {Route, Switch, useHistory} from 'react-router-dom';
import appIcon from './images/apps.svg';
import OlaqinLogo from 'olaqin-design-system/src/assets/img/Olaqin-Logotype-RVB-Noir.svg';
import Galaxie from "./applications/galaxie";
import Stellair from "./applications/stellair";
import NoMatch from "./configurations/routes/NoMatch";
import ProtectedRoute from "./configurations/routes/ProtectedRoutes";
import neo from './images/neo@3x.png';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'fontsource-montserrat';
import 'fontsource-open-sans';
import './theme.scss';
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {EventType} from "@azure/msal-browser";
import {loginRequest} from "./msal";

const HomePage = () => {
    const history = useHistory();

    useEffect(() => {
        history.push('/galaxie'+window.location.search);
    })

    return null;
}

const Routes = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    return (
        isAuthenticated && (
            <Switch>
                <ProtectedRoute exact path="/">
                    <HomePage/>
                </ProtectedRoute>
                <ProtectedRoute exact path="/stellair">
                    <Stellair/>
                </ProtectedRoute>
                <ProtectedRoute exact path="/galaxie">
                    <Galaxie/>
                </ProtectedRoute>
                <ProtectedRoute exact path="/deconnexion" component={() => instance.logoutRedirect()}/>
                <Route path="*">{isAuthenticated ? <NoMatch/> : null}</Route>
            </Switch>)
    );
}

const mockAvailableTerminals = [
    {
        name: 'TERMINAL 1',
        image: neo
    },
    {
        name: 'TERMINAL 2',
        image: neo
    },
    {
        name: 'TERMINAL 3',
        image: neo
    }
];

const App = () => {

    const history = useHistory();
    const logoAction = () => history.push('/');

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [selectedTerminal, setSelectedTerminal] = useState(mockAvailableTerminals[0]);

    useEffect(() => {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
        }

        instance.addEventCallback(
            event => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    const { account } = event.payload;
                    instance.setActiveAccount(account);
                }
            },
            error => {
                console.log('error', error);
            }
        );

        instance
            .handleRedirectPromise()
            .then(authResult => {
                const account = instance.getActiveAccount();
                if (!account) {
                    window.parent !== window
                        ? instance.loginPopup(loginRequest)
                        : instance.loginRedirect({ ...loginRequest, prompt: 'login' });
                } else {
                    instance.acquireTokenSilent(loginRequest);
                    if (['/client', '/partenaire1'].includes(window.location.pathname)) {
                        history.push('/?isredirect=true');
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
        isAuthenticated && (
            <div className="App">
                <header>
                    <Header
                        id="headerPortalId"
                        onClick={logoAction}
                        appIcon={appIcon}
                        logo={OlaqinLogo}
                        logoAction={logoAction}
                        selectedTerminal={selectedTerminal}
                        otherTerminals={mockAvailableTerminals}
                        changeTerminal={terminal => setSelectedTerminal(terminal)}
                        manageTerminalsAction={() => console.log('manage terminals action')}
                    />
                </header>
                <Routes/>
            </div>
        )
    );
}

export default App;
