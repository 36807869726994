import env from '@beam-australia/react-env';
import Cookies from 'js-cookie';

console.log('window.location.search ', window.location.search.split('=')[1]);

let authorityEnv;
let logout;
if (Boolean(window.location.search.split('=')[1]) && Cookies.get('connect')) {
  switch (Cookies.get('connect')) {
    case 'client':
      authorityEnv = env('AUTHORITY_CLIENT');
      logout = env('POST_LOGOUT_REDIRECT_URI_CLIENT');
      break;
    case 'partenaire1':
      authorityEnv = env('AUTHORITY_PARTENAIRE1');
      break;
    default:
      authorityEnv = env('AUTHORITY');
      logout = env('POST_LOGOUT_REDIRECT_URI');
  }
} else {
  switch (window.location.pathname) {
    case '/client':
      authorityEnv = env('AUTHORITY_CLIENT');
      logout = env('POST_LOGOUT_REDIRECT_URI_CLIENT');
      Cookies.set('connect', 'client');
      break;
    case '/partenaire1':
      authorityEnv = env('AUTHORITY_PARTENAIRE1');
      Cookies.set('connect', 'partenaire1');
      break;
    default:
      authorityEnv = env('AUTHORITY');
      logout = env('POST_LOGOUT_REDIRECT_URI');
      Cookies.set('connect', 'olaqin');
  }
}

export const msalConfig = {
  auth: {
    clientId: env('CLIENT_ID'),
    authority: authorityEnv,
    knownAuthorities: [env('KNOWN_AUTHORITIES')],
    redirectUri: env('REDIRECT_URI'),
    postLogoutRedirectUri: logout,
    validateAuthority: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  },
  system: {
    allowRedirectInIframe: true
  }
};

export const loginRequest = {
  scopes: [env('SCOPES'), 'openid', 'profile']
};
