import React from 'react';
import {Iframe} from 'olaqin-design-system';
import {getToken} from "../iframeUtils";
import env from "@beam-australia/react-env";

const Stellair = () => {
    return (
        <Iframe
            id="stellairId"
            origin={env('ORIGIN_STELLAIR')}
            token={getToken()}
        />
    )
};

export default Stellair;
