import React from 'react';
import { Route } from 'react-router-dom';
import {useIsAuthenticated} from "@azure/msal-react";

const ProtectedRoute = ({ children, ...routeProps }) => {
    const isAuthenticated = useIsAuthenticated();
    return <Route {...routeProps}>{isAuthenticated ? children : null}</Route>;
};

export default ProtectedRoute;
