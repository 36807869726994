import React, {useEffect} from 'react';
import {Iframe} from 'olaqin-design-system';
import env from "@beam-australia/react-env";
import { useMsal } from '@azure/msal-react';
import {loginRequest} from "../../msal";
import Cookies from "js-cookie";

const Galaxie = () => {
    const { instance } = useMsal();

    const onReceiveMessage = async e => {
        if (e.type === 'message' && e.data === 'deconnecter' && e.origin === env('ORIGIN_GALAXIE')) {
            await instance.logoutRedirect();
        }
    }

    useEffect(() => {
        window.addEventListener("message", onReceiveMessage);
        instance.acquireTokenSilent(loginRequest);
        return () => {
            window.removeEventListener("message", onReceiveMessage, false);
        };
    })

    const queryconnect = Cookies.get("connect")
    ? `connect=${Cookies.get("connect")}`
    : "";

    return (
        <Iframe
            id="galaxieId"
            origin={env('ORIGIN_GALAXIE')}
            token={{}}
            search={
              window.location.search
                ? window.location.search + "&" + queryconnect
                : "?" + queryconnect
            }
        />
    )
};

export default Galaxie;