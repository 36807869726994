import React from 'react';
import { useLocation } from 'react-router-dom';

const NoMatch = () => {
  const location = useLocation();

  return (
    <div>
      <h3>404</h3>
      <p>
        Nous sommes désolés mais la page <code>{location.pathname}</code> que vous recherchez
        n'existe pas.
      </p>
    </div>
  );
};

export default NoMatch;
