import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {PublicClientApplication} from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import {msalConfig} from "./msal";

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </MsalProvider>,
    document.getElementById('root')
);